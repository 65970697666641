/* eslint-disable no-plusplus */
import React, {useEffect, useMemo, useRef, useState} from 'react'
import {
  WEBGLContextManipulationV1,
  WEBGLContextManipulationV2,
  BadEpochLabelType,
  colorForBadEpoch,
} from 'lib/WEBGL/WEBGLContextManipulation/WEBGLContextManipulation'
import {Header} from 'lib/EdfParser/Edf'
import {WaveGraphPropertySourceImpl} from 'lib/GraphSource'
import GraphViewModel from 'lib/EdfGraph/GraphViewModelV1'
import Select from 'components/V2Select/Select/Select'
import PlayButton from 'assets/svgs/btn-play.svg'
import PauseButton from 'assets/svgs/btn-pause.svg'
import {
  CanvasContainer,
  CanvasParentContainer,
  ChannelContainer,
  ChannelSpan,
  Container,
  DropDownStructure,
  heightOptions,
  SecondsContainer,
  SecondsParentContainer,
  StyledContentTitle,
  StyledControlsInput,
  StyledControlsWrap,
  StyledPlayButton,
  StyledRangeWrap,
  SubContainer,
  TooltipBottomContainer,
  TooltipCenterContainer,
  TooltipLegend,
  TooltipScale,
  TooltipTopContainer,
  widthOptions,
} from 'components/molcules/Report/RawData/RawDataStyles'
import useViewModel from 'components/molcules/Report/RawData/Canvas/BioGraphCanvasViewModel'
import {NormalIconSelectStyles} from 'components/V2Select/IconSelect/Styles'

interface VerticesType {
  propertyGuide: WaveGraphPropertySourceImpl
  edfData?: EDFResultData
  extraparam: any[]
  graphViewModel: GraphViewModel
  containerRef: any
  optionChange: (type: string, value: DropDownStructure) => void
  showBadEpoch: boolean
}

interface EDFResultData {
  header: Header | null
  physicalData: any[]
}

interface LegendProps {
  label: BadEpochLabelType
}

const LegendCircle = ({label}: LegendProps) => {
  const numberToHex = (num: number) => {
    return parseInt((255 * num).toFixed(), 10)
      .toString(16)
      .padStart(2, '0')
  }
  const [red, green, blue] = colorForBadEpoch(label)
  const r = numberToHex(red)
  const g = numberToHex(green)
  const b = numberToHex(blue)
  return (
    <div
      style={{
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        display: 'flex',
      }}
    >
      <svg height='20' width='20'>
        <circle
          cx='10'
          cy='10'
          r='10'
          strokeWidth='3'
          fill={`#${r}${g}${b}99`}
        />
      </svg>
    </div>
  )
}

let timeout: any

const BioGraphCanvas = ({
  edfData,
  extraparam,
  graphViewModel,
  containerRef,
  optionChange,
  showBadEpoch,
  propertyGuide,
}: VerticesType) => {
  const CHANNELS = propertyGuide.getChannelLabels()

  const TOTAL_NUMBER_OF_SECONDS = edfData?.header?.numberOfDataRecords
  const TOTAL_CANVAS = graphViewModel.getGraph().SET_OF_INDICES.length

  // const SAMPLE_WIDTH = 125
  // const CHANNEL_HEIGHT = 40
  const SAMPLE_WIDTH = 100 // 80%
  const CHANNEL_HEIGHT = 32 // 80%
  const NUMBER_OF_SAMPLES_IN_SECOND = 250
  const NUMBER_OF_CHANNELS_IN_FRAME = 19 // eeg : 19, ppg : 1

  const [secPerPage, setSecPerPage] = useState(0)
  const [scaleWidth, setScaleWidth] = useState(1)
  const [isMouseFocus, setIsMouseFocus] = useState(false)

  const {
    montageOption,
    selectedOptionWidth,
    selectedOptionHeight,
    scrollX,
    playState,
    resetValue,
    time,

    setMontageOption,
    setSelectedOptionWidth,
    setSelectedOptionHeight,
    setScrollX,
    setPlayState,
    setResetValue,
    setTime,
  } = useViewModel()

  const slideHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = graphViewModel.handleSlide(event, containerRef)
    setScrollX(value)
  }

  const montageOptions = useMemo<DropDownStructure[]>(() => {
    const montageOptions = propertyGuide.filterOptions()
    setMontageOption(montageOptions[0])
    return montageOptions
  }, [propertyGuide.graphColor()])

  useEffect(() => {
    optionChange('Width', selectedOptionWidth)
    optionChange('Height', selectedOptionHeight)
    optionChange('Montage', montageOption)
  }, [optionChange])

  useEffect(() => {
    graphViewModel.setTimeHandler(setTime)
    graphViewModel.setPlayStateHandler(setPlayState)
    graphViewModel.setResetValueHandler(setResetValue)
  }, [setTime, setResetValue, setPlayState])

  const handleResize = (entries: ResizeObserverEntry[]) => {
    const secPer =
      Math.floor(entries[0].contentRect.width) / NUMBER_OF_SAMPLES_IN_SECOND
    setSecPerPage(secPer)
  }

  useEffect(() => {
    const observer = new ResizeObserver(handleResize)
    if (containerRef.current) {
      observer.observe(containerRef.current)
    }
    return () => {
      observer.disconnect()
    }
  }, [containerRef])

  const scrollInterval = useRef<any>(null)
  const [glArr, setGlArr] = useState<WebGLRenderingContext[]>([])
  const [canvaArr, setCanvaArr] = useState<NonNullable<HTMLCanvasElement>[]>([])
  const [canvRefs, setCanvRefs] = useState(
    Array.from({length: TOTAL_CANVAS}, () =>
      React.createRef<HTMLCanvasElement>(),
    ),
  )

  useEffect(() => {
    setCanvRefs(
      Array.from({length: TOTAL_CANVAS}, () =>
        React.createRef<HTMLCanvasElement>(),
      ),
    )
  }, [TOTAL_CANVAS])

  const updateCanvasWidth = () => {
    const devicePixelRatio = window.devicePixelRatio || 1
    for (let k = 0; k < canvRefs.length; k++) {
      if (canvaArr[k]) {
        const displayWidth = canvaArr[k].clientWidth
        const displayHeight = canvaArr[k].clientHeight
        canvaArr[k].width = displayWidth * devicePixelRatio
        canvaArr[k].height = displayHeight * devicePixelRatio
      }
    }
  }

  useEffect(() => {
    if (canvaArr.length < canvRefs.length) {
      for (let i = 0; i < canvRefs.length; i++) {
        const canvas = canvRefs[i].current
        if (canvas) {
          canvaArr[i] = canvas
          const gl = canvas.getContext('webgl')
          if (gl) {
            glArr[i] = gl
          } else {
            console.error('WebGL is not supported in this browser.', i)
          }
        }
      }
      setCanvaArr(canvaArr)
      setGlArr(glArr)
      updateCanvasWidth()
    }
  }, [canvRefs])

  const setTimeAccordingToScrollPosition = () => {
    if (containerRef && containerRef.current) {
      const perSecondWidth = containerRef.current.clientWidth / secPerPage
      const currentTime = containerRef.current.scrollLeft / perSecondWidth
      graphViewModel.setTime(currentTime)
    }
  }

  const scrollHandler = (event: React.UIEvent<HTMLDivElement>): void => {
    setTimeAccordingToScrollPosition()
    const value = graphViewModel.handleScroll(event)
    setScrollX(value)
  }

  const createArrOfSec = () => {
    const arr = []
    if (!TOTAL_NUMBER_OF_SECONDS) {
      return
    }
    for (let i = 0; i < TOTAL_NUMBER_OF_SECONDS; i++) {
      arr.push(i)
    }
    // eslint-disable-next-line consistent-return
    return arr
  }

  const graph = graphViewModel.getGraph()
  const hasRemainder = graph.REMAINDER !== 0
  let LAST_CANVAS_WIDTH: number
  if (hasRemainder) {
    LAST_CANVAS_WIDTH = SAMPLE_WIDTH * scaleWidth * graph.REMAINDER
  } else {
    LAST_CANVAS_WIDTH = 0
  }
  const CANVAS_WIDTH = SAMPLE_WIDTH * scaleWidth * graph.SECONDS_PER_CANVAS
  const CANVAS_HEIGHT = NUMBER_OF_CHANNELS_IN_FRAME * CHANNEL_HEIGHT

  const getCanvContWidth = () => {
    let allCanvWidth
    if (graphViewModel.getGraph().REMAINDER > 0) {
      allCanvWidth = CANVAS_WIDTH * (canvRefs.length - 1) + LAST_CANVAS_WIDTH
    } else {
      allCanvWidth = CANVAS_WIDTH * canvRefs.length
    }
    return allCanvWidth
  }

  const CANVAS_CONTAINER_WIDTH = getCanvContWidth()
  const arrOfSeconds = createArrOfSec()

  const manipulateWEBGLContext = (ind: number) => {
    if (extraparam[ind]?.length > 0) {
      const option = {
        index: ind,
        showBadEpoch,
        scaleWidth,
        timesRejectedData: propertyGuide.timeRejections(),
        colorType: propertyGuide.graphColor(),
      }
      WEBGLContextManipulationV1(
        glArr[ind],
        extraparam[ind],
        canvaArr[ind],
        option,
      )
    }
    const optionV2 = {
      index: ind,
      showBadEpoch,
      scaleWidth,
      timesRejectedData: propertyGuide.timeRejections(),
    }
    WEBGLContextManipulationV2(
      glArr[ind],
      extraparam[ind],
      canvaArr[ind],
      optionV2,
    )
  }

  useEffect(() => {
    for (let m = 0; m < canvRefs.length; m++) {
      manipulateWEBGLContext(m)
    }
  }, [scaleWidth, extraparam, canvRefs, showBadEpoch])

  // to handle the play functionality
  useEffect(() => {
    if (playState) {
      graphViewModel.onPlay(containerRef, scrollInterval, 'start')
    } else {
      if (resetValue === 'reset') {
        graphViewModel.onPlay(containerRef, scrollInterval, 'reset')
      }
      if (resetValue === 'pause') {
        graphViewModel.onPlay(containerRef, scrollInterval, 'pause')
      }
    }
  }, [playState, resetValue])

  const handleOptionChangeHeight = (index: number) => {
    const selected = heightOptions[index]
    const {optionValue} = selected
    if (selectedOptionHeight) {
      optionChange('Height', selected)
      graphViewModel.updateAmplitudeFactor(optionValue)
      setSelectedOptionHeight(selected)
    }
  }

  const handleOptionChangeWidth = (index: number) => {
    const selected = widthOptions[index]
    const {optionValue} = selected
    graphViewModel.setSpeed(optionValue)
    if (selectedOptionWidth) {
      optionChange('Width', selected)
      setScaleWidth(optionValue)
      setSelectedOptionWidth(selected)
    }
  }

  const handleOptionChangeMontage = (index: number) => {
    const selected = montageOptions[index]
    optionChange('Montage', selected)
    setMontageOption(selected)
  }

  const playPauseHandler = () => {
    if (playState) {
      setResetValue('pause')
      setPlayState(false)
    } else {
      if (time !== '00: 00' && time !== '00:00') {
        setResetValue('pause')
      }
      setPlayState(true)
    }
  }

  const a = BadEpochLabelType.Asr
  const b = BadEpochLabelType.BadEpoch

  const parentRef = useRef<any>()

  const finalizeCleanUp = (e: any) => {
    if (e.propertyName === 'opacity') {
      if (!isMouseFocus) {
        e.target.style.display = 'none'
      }
      e.target.removeEventListener('transitionend', finalizeCleanUp)
    }
  }

  const handleTooltipHide = (elements?: any[]) => {
    setIsMouseFocus(false)
    if (parentRef.current && timeout) {
      clearTimeout(timeout)
      timeout = null
      let e = elements
      if (elements === undefined) {
        e = Array.from(parentRef.current.getElementsByClassName('tooltip'))
      }
      if (e) {
        e.forEach((it: any) => {
          it.removeEventListener('transitionend', finalizeCleanUp)
          it.style.opacity = '0'
          it.style.transition = 'opacity 0.25s'
          it.addEventListener('transitionend', finalizeCleanUp)
        })
      }
    }
  }

  const initTimeout = () => {
    timeout = setTimeout(() => {
      handleTooltipHide()
    }, 3000)
  }

  const handleTooltipShow = () => {
    setIsMouseFocus(true)
    if (parentRef.current) {
      const elements = Array.from(
        parentRef.current.getElementsByClassName('tooltip'),
      )
      elements.forEach((it: any) => {
        if (it.style.display !== 'flex') {
          it.removeEventListener('transitionend', finalizeCleanUp)
          it.style.display = 'flex'
          it.style.opacity = '1'
        }
      })
      if (timeout) {
        clearTimeout(timeout)
        initTimeout()
        return
      }

      elements.forEach((it: any) => {
        it.removeEventListener('transitionend', finalizeCleanUp)
        it.style.display = 'flex'
        it.style.opacity = '1'
      })
      initTimeout()
    }
  }

  return (
    <Container
      data-testid='graph-container'
      ref={parentRef}
      onMouseMove={(e) => {
        handleTooltipShow()
      }}
      onMouseLeave={(e) => {
        handleTooltipHide()
      }}
    >
      <SubContainer style={{borderWidth: '1px'}}>
        <ChannelContainer ContainerHeight={CANVAS_HEIGHT}>
          {CHANNELS?.map((channel, idx) => {
            return (
              <ChannelSpan key={idx + 1}>
                <strong style={{fontSize: '14px'}}>{channel.label}</strong>
              </ChannelSpan>
            )
          })}
          {/* * invisible text to manage corresponding label for channels */}
        </ChannelContainer>
        <CanvasParentContainer
          ref={containerRef}
          onScroll={scrollHandler}
          CANVAS_CONTAINER_WIDTH={CANVAS_CONTAINER_WIDTH}
        >
          <CanvasContainer CANVAS_CONTAINER_WIDTH={CANVAS_CONTAINER_WIDTH}>
            {canvRefs.map((elem, i) => {
              return (
                <canvas
                  key={i + 1}
                  ref={canvRefs[i]}
                  style={{
                    width:
                      graphViewModel.getGraph().REMAINDER > 0 &&
                      i === canvRefs.length - 1
                        ? LAST_CANVAS_WIDTH
                        : CANVAS_WIDTH,
                    height: `${CANVAS_HEIGHT}px`,
                  }}
                />
              )
            })}
          </CanvasContainer>
          <SecondsParentContainer
            CANVAS_CONTAINER_WIDTH={CANVAS_CONTAINER_WIDTH}
          >
            {arrOfSeconds?.map((sec, index) => {
              return (
                <SecondsContainer
                  key={index + 1}
                  ContainerWidth={
                    CANVAS_CONTAINER_WIDTH / (TOTAL_NUMBER_OF_SECONDS || 1)
                  }
                >
                  <p style={{fontSize: '12px'}}>
                    {graphViewModel.secondsWithPadding(sec)}
                  </p>
                </SecondsContainer>
              )
            })}
          </SecondsParentContainer>
        </CanvasParentContainer>
      </SubContainer>

      <TooltipTopContainer className='tooltip'>
        {showBadEpoch && propertyGuide.timeRejections() && (
          <TooltipLegend>
            <LegendCircle label={a} />
            <StyledContentTitle>{a}</StyledContentTitle>
            <LegendCircle label={b} />
            <StyledContentTitle>{b}</StyledContentTitle>
          </TooltipLegend>
        )}

        <TooltipScale>
          <StyledContentTitle>Width</StyledContentTitle>
          <span>
            <Select
              thumb={selectedOptionWidth.optionTitle}
              theme={NormalIconSelectStyles}
              handler={(_, index) => {
                handleOptionChangeWidth(index)
              }}
              options={widthOptions.map((option) => `${option.optionTitle}`)}
            />
          </span>
          <div style={{margin: '8px'}} />
          <StyledContentTitle>Height</StyledContentTitle>
          <span>
            <Select
              thumb={selectedOptionHeight.optionTitle}
              theme={NormalIconSelectStyles}
              handler={(_, index) => {
                handleOptionChangeHeight(index)
              }}
              options={heightOptions.map((option) => `${option.optionTitle}`)}
            />
          </span>
          {propertyGuide.isDisplayOptions() && (
            <>
              <div style={{margin: '8px'}} />
              <StyledContentTitle>Montage</StyledContentTitle>
              <span>
                <Select
                  thumb={montageOption.optionTitle}
                  theme={NormalIconSelectStyles}
                  handler={(_, index) => {
                    handleOptionChangeMontage(index)
                  }}
                  options={montageOptions.map(
                    (option) => `${option.optionTitle}`,
                  )}
                />
              </span>
            </>
          )}
        </TooltipScale>
      </TooltipTopContainer>
      <TooltipCenterContainer className='tooltip'>
        <StyledPlayButton
          className='playButton'
          type='button'
          onClick={() => {
            playPauseHandler()
          }}
        >
          <img
            src={playState ? PauseButton : PlayButton}
            alt='start'
            style={{width: '100px', height: '100px'}}
          />
        </StyledPlayButton>
      </TooltipCenterContainer>
      <TooltipBottomContainer className='tooltip'>
        <StyledRangeWrap>
          <StyledControlsWrap>
            {containerRef.current && (
              <StyledControlsInput
                type='range'
                id='volume'
                name='volume'
                min={0}
                max={
                  containerRef.current
                    ? containerRef.current?.scrollWidth -
                      containerRef.current?.clientWidth
                    : 10000
                }
                value={scrollX}
                onChange={(event) => {
                  setResetValue('pause')
                  setPlayState(false)
                  return slideHandler(event)
                }}
              />
            )}
          </StyledControlsWrap>
        </StyledRangeWrap>
      </TooltipBottomContainer>
    </Container>
  )
}

export default BioGraphCanvas
