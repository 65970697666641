import React from 'react'
import {AnalysisResultItem, IcaAnalysisResultIndicator} from 'api/analysisApi'
import WaveGraphPropertyFactory, {
  WaveGraphPropertySourceType,
} from 'lib/GraphSource'
import {HorizontalImageGraph} from 'components/molcules/Report/RawData/HorizontalImageGraph'
import ReportHeader from 'components/Report/ReportHeader'
import LeftChannel from 'assets/img/LeftChannel.jpeg'
import {WaveGraph} from 'pages/Analysis/EEGIndividual/ReAnalysis/IcaReport/IcaRawData'

interface RawDataProps {
  readonly indicator: IcaAnalysisResultIndicator
  readonly items: AnalysisResultItem[]
  readonly yAxisImage?: string
  readonly type?: string
}

function IcaRawDataV1({
  indicator,
  items,
  yAxisImage = LeftChannel,
  type,
}: RawDataProps) {
  const [item] = items

  const renderRawData = () => {
    if (!item) return null

    const commonWaveGraphProps = {
      raw: {url: item.rawDataNamePath},
      image: item.rawDataPath,
    }

    if (item.rawDataNamePath) {
      const propertyGuide = (() => {
        switch (type) {
          case 'raw':
            return WaveGraphPropertyFactory.sourceOfType(
              WaveGraphPropertySourceType.Raw,
              item.rejectedTime ? JSON.parse(item.rejectedTime) : [],
            )
          case 'component':
            return WaveGraphPropertyFactory.sourceOfType(
              WaveGraphPropertySourceType.Component,
            )
          case 'cleaned':
            return WaveGraphPropertyFactory.sourceOfType(
              WaveGraphPropertySourceType.Cleaned,
            )
          default:
            return null
        }
      })()

      return propertyGuide ? (
        <WaveGraph {...commonWaveGraphProps} propertyGuide={propertyGuide} />
      ) : null
    }

    return (
      <HorizontalImageGraph
        xAxisImg={`${item.rawDataPath}?t=${Date.now()}`}
        yAxisImg={yAxisImage}
      />
    )
  }

  return (
    <div>
      <ReportHeader
        index={indicator.codeSort}
        title={indicator.codeName}
        description={indicator.titleDescription}
      />
      {renderRawData()}
    </div>
  )
}

export default IcaRawDataV1
