import {ReportIcaAnalysisTypeMap} from 'constants/AnalysisConstant'
import IcaRawDataV1 from 'pages/Analysis/EEGIndividual/ReAnalysis/IcaReport/IcaRawDataV1'
import {RenderingItem} from 'pages/Analysis/EEGIndividual/ReAnalysis/IcaReport/IcaReportFetcher'
import React from 'react'

export interface IcaReportItemProps {
  renderingItem: RenderingItem
}

export function TsrReportItem({renderingItem}: IcaReportItemProps) {
  const [indicator, items] = renderingItem

  return (
    <div>
      {indicator.codeId === ReportIcaAnalysisTypeMap.CleanedData && (
        <IcaRawDataV1 indicator={indicator} items={items} type='cleaned' />
      )}
    </div>
  )
}
