import React from 'react'
import ICAComponent from 'components/molcules/Report/ICAComponent'
import {CodeType, ReportIcaAnalysisTypeMap} from 'constants/AnalysisConstant'
import IcaRawDataV1 from 'pages/Analysis/EEGIndividual/ReAnalysis/IcaReport/IcaRawDataV1'
import {RenderingItem} from 'pages/Analysis/EEGIndividual/ReAnalysis/IcaReport/IcaReportFetcher'
import LeftNumeric from 'assets/img/LeftNumeric.jpeg'

export interface IcaReportItemProps {
  renderingItem: RenderingItem
  codeType: CodeType
}

export function IcaReportItem({renderingItem, codeType}: IcaReportItemProps) {
  const [indicator, items] = renderingItem

  return (
    <div>
      {indicator.codeId === ReportIcaAnalysisTypeMap.RawData && (
        <IcaRawDataV1 indicator={indicator} items={items} type='raw' />
      )}
      {indicator.codeId ===
        ReportIcaAnalysisTypeMap.ComponentDataTimeSeries && (
        <IcaRawDataV1
          indicator={indicator}
          items={items}
          yAxisImage={LeftNumeric}
          type='component'
        />
      )}
      {indicator.codeId === ReportIcaAnalysisTypeMap.ComponentData && (
        <ICAComponent
          indicator={indicator}
          items={items}
          codeType={codeType}
          componentList={[]}
          hideDescription
          hideAllComponentTimeSeries
        />
      )}
      {indicator.codeId === ReportIcaAnalysisTypeMap.CleanedData && (
        <IcaRawDataV1 indicator={indicator} items={items} type='cleaned' />
      )}
    </div>
  )
}
